"use client";

import React, { useEffect, useState } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import style from "./searchProducts.module.scss";
import { Products } from "@/common/entities/product/Product";
import { searchProducts } from "@/services/ProductsService";
import SearchProductsTitleAndCategory from "@/components/SearchProducts/SearchProductsTitleAndCategory";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import ProductsUtils from "@/common/utils/ProductsUtils";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import useDebounce from "@/common/hooks/useDebounce";
import { Configurators } from "@/common/entities/configurators/Configurator";
import { SitesCategoriesSearch } from "@/common/entities/sites/SiteCategories";

const SearchProducts = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState<Products[]>([]);
    const [sitesCategories, setSitesCategories] = useState<SitesCategoriesSearch[]>([]);
    const [configurators, setConfigurators] = useState<Configurators[]>([]);
    const router = useRouter();
    const searchParams = useSearchParams();

    useEffect(() => {
        const query = searchParams.get("query");
        if (query) {
            setSearchTerm(query);
            updateDebounceSearch(query);
        }
    }, [searchParams]);

    const filterProducts = (searchValue: string) => {
        if (searchValue.length >= 2) {
            searchProducts({ from: 0, size: 7, search: searchValue })
                .then((response) => {
                    setProducts(response.productsData.data);
                    setConfigurators(response.configuratorsData.data);
                    setSitesCategories(response.siteCategoriesData.data);
                })
                .catch(() => {
                    setProducts([]);
                    setConfigurators([]);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const debouncedFetchSearchResults = useDebounce(filterProducts, 300);

    const updateDebounceSearch = (searchValue: string) => {
        setSearchTerm(searchValue);
        debouncedFetchSearchResults(searchValue);
    };

    const clearInfo = () => {
        setTimeout(() => {
            setIsFocused(false);
            setProducts([]);
            setSitesCategories([]);
            setConfigurators([]);
            setSearchTerm("");
        }, 100);
    };

    const getDefaultConfiguratorCategoryName = (configurator: Configurators) => {
        if (!configurator.category) {
            return "";
        }

        return configurator.category.name ?? "";
    };

    const handleSearchSubmit = () => {
        if (searchTerm.trim().length >= 3) {
            router.push(`/search?query=${encodeURIComponent(searchTerm)}`);
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            setIsFocused(false);
            handleSearchSubmit();
        }
    };

    return (
        <div className={style.searchBar}>
            <div className={`${style.searchboxBack} ${isFocused ? style.active : ""}`}></div>
            <InputGroup className={`${isFocused ? style.inputHoverEffect : ""}`}>
                <InputGroup.Text className={style.inputText} onClick={handleSearchSubmit}>
                    <FaSearch />
                </InputGroup.Text>
                <FormControl
                    type="text"
                    value={searchTerm}
                    onChange={(e) => updateDebounceSearch(e.target.value)}
                    placeholder="Cauta ..."
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => clearInfo()}
                    onKeyDown={onKeyDown}
                    inputMode="search"
                />
            </InputGroup>
            {searchTerm && isFocused && (
                <div className={style.searchResult}>
                    {isLoading ? (
                        <div className="ps-3 pt-1">Se Cauta Produse...</div>
                    ) : (
                        <>
                            <Row className="m-0">
                                <Col>
                                    {products && products.length > 0 && (
                                        <>
                                            <div className={style.headerSearch}>Sugestii de produse</div>
                                            {products.map((product: Products, index: number) => {
                                                const productLink = ProductsUtils.getProductLink(product.url, product.categories);

                                                return (
                                                    <Link
                                                        href={productLink}
                                                        shallow={true}
                                                        passHref
                                                        key={index}
                                                        className={`d-flex align-items-center ${style.searchItem}`}
                                                    >
                                                        <ImageWrapper
                                                            width={60}
                                                            height={60}
                                                            url={product.defaultImage?.image.smallSizeFileName ?? product.defaultImage?.image?.originalFileName}
                                                            title={product.name}
                                                        />

                                                        <SearchProductsTitleAndCategory title={product.name} productCode={product.productCode ?? ""} />
                                                    </Link>
                                                );
                                            })}
                                        </>
                                    )}

                                    {configurators &&
                                        configurators.length > 0 &&
                                        configurators.map((configurator: Configurators, index: number) => {
                                            const categoryName = getDefaultConfiguratorCategoryName(configurator);
                                            const productLink = `${configurator.category.url}/${configurator.url}`;

                                            return (
                                                <Link
                                                    href={productLink}
                                                    shallow={true}
                                                    passHref
                                                    key={index}
                                                    className={`d-flex align-items-center ${style.searchItem}`}
                                                >
                                                    <ImageWrapper
                                                        width={80}
                                                        height={80}
                                                        url={
                                                            configurator.configuratorImages[0].image.smallSizeFileName ??
                                                            configurator.configuratorImages[0].image.originalFileName
                                                        }
                                                        title={configurator.name}
                                                    />

                                                    <SearchProductsTitleAndCategory title={configurator.name} productCode={""} categoryName={categoryName} />
                                                </Link>
                                            );
                                        })}
                                </Col>
                                {sitesCategories && sitesCategories.length > 0 && (
                                    <Col md={4}>
                                        <div className={style.headerSearch}>Sugestii de categorii</div>
                                        {[...sitesCategories]
                                            .sort((a, b) => Number(b.productCount) - Number(a.productCount))
                                            .slice(0, 10)
                                            .map((sitesCategory: SitesCategoriesSearch, index: number) => {
                                                return (
                                                    <Link
                                                        href={"/" + sitesCategory?.redirectUrl}
                                                        shallow={true}
                                                        passHref
                                                        key={index}
                                                        className={style.searchItemCategory}
                                                    >
                                                        {sitesCategory.siteCategoriesNames}
                                                        <small className={style.productCount}>({sitesCategory.productCount})</small>
                                                    </Link>
                                                );
                                            })}
                                    </Col>
                                )}
                            </Row>

                            <Row>
                                <Col>
                                    {configurators?.length === 0 && products?.length === 0 && (
                                        <div className="p-3">Nu am gasit produse cu aceasta cautare.</div>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchProducts;
