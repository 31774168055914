import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheck, FaCopy } from "react-icons/fa";
import styled from "styled-components";

export interface CopyTextProps {
    text: string;
    className?: string;
}

const Container = styled.div`
    cursor: pointer;
    color: var(--text-color);
`;

const CopyText = ({ text, className = "", ...props }: CopyTextProps) => {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard() {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard().then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        });
    };

    return (
        <Container className={`${className} `} {...props}>
            <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
                <Button variant="default" onClick={handleCopyClick} className="p-0">
                    {isCopied ? <FaCheck /> : <FaCopy />}
                </Button>
            </OverlayTrigger>
        </Container>
    );
};

export default CopyText;
