import { useCallback, useRef } from "react";

/**
 * Custom hook to debounce a function.
 * @param func The function to debounce (can be async).
 * @param delay The debounce delay in milliseconds.
 * @returns A debounced version of the function.
 */
function useDebounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
): (...args: Parameters<T>) => void {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return useCallback((...args: Parameters<T>) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            func(...args);
        }, delay);
    }, [func, delay]);
}

export default useDebounce;