"use client";

import React, { useEffect, useState } from "react";
import MobileNavBar from "@/components/layout/navbar/MobileNavBar";
import AppNavbar from "@/components/layout/navbar/AppNavbar";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import { SitesBasic } from "@/common/entities/sites/SitesBasic";
import { SitesPagesResponse } from "@/common/entities/sites/SitePages";
import { generateFullCheckoutCart } from "@/common/helpers/util.functions";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import { CheckoutCart } from "@/components/Cart/CartForm/CartForm";
import { CartItem } from "@/common/entities/cart/CartItem";
import { ProductType } from "@/common/entities/product/Product";

interface NavbarProps {
    isMobile: boolean;
    sitesCategories: SitesCategories[];
    siteDetails?: SitesBasic;
    pages?: SitesPagesResponse;
}

const NavbarWrapper: React.FC<NavbarProps> = ({ isMobile, pages, sitesCategories, siteDetails }) => {
    const [totalProducts, setTotalProducts] = useState(0);
    const cartLocalStorage = generateFullCheckoutCart(LocalStorageService.getItem(LocalStorageKeys.cart));
    const [cart, setCart] = useState<CheckoutCart | null>(cartLocalStorage);

    useEffect(() => {
        let total =
            cart?.cartProducts?.reduce((acc, item: CartItem) => {
                if (item.originConfiguratorCode) {
                    return acc;
                }

                if ([ProductType.glass, ProductType.door].includes(item.product.type)) {
                    return acc + 1;
                }

                return acc + Number(item.quantity);
            }, 0) || 0;

        total += cart?.configurators?.length || 0;
        setTotalProducts(total);
    }, [cart]);

    useEffect(() => {
        const handleStorageUpdate = () => {
            const updatedCart = LocalStorageService.getItem(LocalStorageKeys.cart) || null;
            setCart(generateFullCheckoutCart(updatedCart));
        };

        window.addEventListener("storageUpdate", handleStorageUpdate);
        return () => window.removeEventListener("storageUpdate", handleStorageUpdate);
    }, []);

    return (
        <>
            {isMobile ? (
                <MobileNavBar totalProducts={totalProducts} logo={siteDetails?.logo} phone={siteDetails?.phone} sitesCategories={sitesCategories} />
            ) : (
                <AppNavbar
                    cart={cart}
                    totalProducts={totalProducts}
                    phone={siteDetails?.phone}
                    pages={pages?.data}
                    logo={siteDetails?.logo}
                    sitesCategories={sitesCategories}
                />
            )}
        </>
    );
};

export default NavbarWrapper;
