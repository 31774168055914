import { SitesBasic } from "@/common/entities/sites/SitesBasic";

export interface SitesPages {
    id: number;
    site: SitesBasic;
    title: string;
    description: string;
    metaTitle: string;
    metaDescription: string;
    metaKeys: string;
    keywords: string;
    url: string;
    active: boolean;
    hydraRoute: string;
    linkPosition: LinkPosition;
    display: boolean;
}

export interface SitesPagesResponse {
    data: SitesPages[];
    total: number;
}

export enum LinkPosition {
    Header = "navbar",
    Footer = "footer",
}
